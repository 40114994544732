import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import Fastlane from '../animations/fastlane.json'

import CrashSafety from '../images/highestCrashSafety.png'
import Trophy from '../images/trophy.svg'

var lottie

export default class BetterCallQuality extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: Fastlane,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Industry Leading Call Quality</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony2'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Call Quality Is Our Top Priority
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              In Order To Get The Phone Right, We Removed The Middle Men
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width={[1, 1/2]} pb="2rem">
                <div
                  ref={animationDiv => {
                    this.animationContainer = animationDiv
                  }}
                />
            </Box>
            <Box width={[1, 3/4]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              YOUR VERY OWN GLOBAL FAST LANE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Other products use low-quality 3rd party networks which results in latency, dropped calls and other problems. We own and operate a first-class global network, leveraging partnerships with the top phone operators in each region, to guarantee the highest call quality in the industry.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperBackground
          color={colors.trulyDark}
          background='racecar'
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/2]}>
            </Box>
            <Box width={[1, 1/2]} pr={['0pt', '32pt']}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              ADAPTIVE CALL QUALITY CONTROL WITH TRUE-VOIP
              </Title3>
              <Large as="p" color={colors.white}>
                When your internet connection shows you its worst side, True-VOIP shows you its best.  Engineered to automatically adjust the quality of audio transmitted as your connectivity changes, we keep your conversations going without interruption.  When call quality crosses dangerously low thresholds, True-VOIP advises you on how to improve quality and even lets you transition calls to your cell phone without your prospect even knowing.
              </Large>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1, 1/2]}>
              <IconImg src={Trophy} />
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              THE HIGHEST CRASH SAFETY RATING IN THE INDUSTRY
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              App crashes are crippling for your team, so we built software three times as reliable as our next competitor. Truly outperforms Salesforce CTI and Chrome Extension based dialers every time.
              </Large>
            </Box>
            <Box width={[1, 1/2]} pb="2rem">
              <WidthImg src={CrashSafety} alt="Stability"/>
            </Box>
          </Section>
        </WrapperSolid>

      </Default>
    )
  }
}


const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const IconImg = styled.img`
  display: block;
  width: 140px;
  padding: 16pt;
  height: auto;
  margin: 0 auto;
`
